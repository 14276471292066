<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          v-model="listSearch.title"
          placeholder="请输入任务名称"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">学校</label>
        <el-select
          size="small"
          clearable
          style="width: 280px"
          v-model="listSearch.schoolId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          @change="selectSchool"
          :loading="loading"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">学校名称</label>
        <el-input
          v-model="listSearch.schoolName"
          placeholder="请输入学校名称"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集类型</label>
        <el-select
          style="width: 130px"
          clearable
          v-model="listSearch.shotSubject"
          placeholder="采集类型"
          autocomplete="off"
          size="small"
        >
          <el-option :key="0" label="新生" :value="0"> </el-option>
          <el-option :key="1" label="毕业生" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">是否生成批次</label>
        <el-select
          style="width: 130px"
          clearable
          v-model="listSearch.isBatch"
          placeholder="是否生成批次"
          autocomplete="off"
          size="small"
        >
          <el-option :key="0" label="未生成" :value="0"> </el-option>
          <el-option :key="1" label="已生成" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">创建时间</label>
        <el-date-picker
          clearable
          v-model="listSearch.stime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          clearable
          v-model="listSearch.etime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          v-if="searchButton"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >搜索
        </el-button>
        <el-button
          v-if="addButton"
          type="success"
          icon="el-icon-plus"
          @click="handleAdd"
          size="small"
          >新建采集任务
        </el-button>
      </div>
    </div>

    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="taskList"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        style="width: 100%"
      >
        <el-table-column prop="title" label="任务名称" width="260" fixed>
        </el-table-column>
        <el-table-column prop="batchName" label="采集批次" width="250">
        </el-table-column>
        <el-table-column
          prop="collectType"
          label="采集类型"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.collectType === 0">校拍</span>
            <span v-if="scope.row.collectType === 1">散拍</span>
            <span v-if="scope.row.collectType === 2">线上采集</span>
          </template>
        </el-table-column>
        <!--                <el-table-column prop="schoolName" label="学校" width="180">-->
        <!--                </el-table-column>-->
        <el-table-column
          prop="beginDate"
          :formatter="columnDateFormat"
          label="开始时间"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="endDate"
          label="结束时间"
          :formatter="columnDateFormat"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          :formatter="columnDateFormat"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="photographer"
          label="摄影师"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="任务状态"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status === 0"
              type="info"
              effect="plain"
              size="small"
              >新建</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 1"
              type="success"
              size="small"
              effect="plain"
              >已结束</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 2"
              type="warning"
              size="small"
              effect="plain"
              >已取消</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="progresses"
          align="center"
          label="处理进度"
          width="240"
        >
          <template slot-scope="scope">
            <span v-html="scope.row.progresses"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="导入记录"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              v-if="viewButton"
              type="text"
              @click="handleViewrecord(scope.row.id)"
              >查看
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="备注" width="260" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              v-if="updateButton"
              v-show="scope.row.status === 0"
              type="text"
              @click="handleUpdate(scope.row.id)"
              >修改
            </el-button>
            <el-button
              v-if="viewButton"
              type="text"
              @click="handleView(scope.row.id)"
              >查看
            </el-button>

            <el-button
              v-show="scope.row.status == 0"
              v-if="finishedButton"
              type="text"
              @click="handleFinished(scope.row.id)"
              >结束任务
            </el-button>
            <el-button
              v-if="cencelButton"
              type="text"
              v-show="scope.row.status === 0"
              @click="handleCencel(scope.row.id)"
              >取消任务
            </el-button>

            <el-button
              v-show="scope.row.status !== 0 && scope.row.status !== 2"
              v-if="downloadXpButton"
              type="text"
              @click="handleDownloadXp(scope.row.id)"
              >打包下载
            </el-button>

            <el-button
              v-show="scope.row.status !== 2"
              v-if="viewStudentButton"
              type="text"
              @click="handleViewStudent(scope.row.id)"
              >学生信息
            </el-button>
            <el-button
              type="text"
              v-show="scope.row.status == 0"
              @click="handleViewStuUpload(scope.row.id)"
              >上传学生信息
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 新增任务 -->
    <el-dialog
      :title="
        dialogType === 1
          ? '新增采集计划'
          : dialogType === 2
          ? '查看任务'
          : '修改任务'
      "
      :visible.sync="dialogVisible"
      width="1100px"
      center="center"
    >
      <div style="max-height: 600px; overflow: hidden; overflow-y: auto">
        <el-form
          ref="taskForm"
          :model="taskForm"
          label-width="180px"
          :rules="taskRules"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="采集类型" prop="appointmentType">
                <el-radio-group
                  v-model="taskForm.collectType"
                  :disabled="dialogType === 2 || dialogType === 3"
                >
                  <el-radio :label="0">校拍</el-radio>
                  <el-radio :label="1">散拍</el-radio>
                  <el-radio :label="2"> 线上采集</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="选择学校"
                prop="riqiDate"
                :rules="[
                  {
                    required:
                      taskForm.collectType == 0 && taskForm.schoolId == null,
                    message: '请输入学校信息',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  clearable
                  style="width: 280px"
                  v-model="taskForm.schoolId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入学校名或代码检索"
                  :disabled="dialogType === 2 || dialogType === 3"
                  :remote-method="remoteMethod"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in schools"
                    :key="item.id"
                    :label="item.schoolFilterName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务名称" prop="title" style="width: 100%">
                <el-input
                  v-model="taskForm.title"
                  style="width: 280px"
                  type="text"
                  placeholder="请输入任务名称"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人" prop="contactPerson">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.contactPerson"
                  placeholder="请输入联系人"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" prop="contactTell">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.contactTell"
                  placeholder="请输入联系方式"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="开始时间" prop="beginDate">
                <el-date-picker
                  style="width: 280px"
                  v-model="taskForm.beginDate"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择开始时间"
                  :disabled="dialogType === 2"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束时间" prop="endDate">
                <el-date-picker
                  style="width: 280px"
                  v-model="taskForm.endDate"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择结束时间"
                  :disabled="dialogType === 2"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="收费标准" prop="shotPrice">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.shotPrice"
                  placeholder="请输入收费标准"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="dialogType === 2 || dialogType === 3">
              <!-- 查看摄影师 -->
              <el-form-item label="摄影师" prop="photographer">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.photographer"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- 编辑摄影师 -->
            <el-col :span="12" v-if="dialogType === 1">
              <el-form-item label="摄影师" prop="photographer">
                <el-select
                  style="width: 280px"
                  v-model="taskForm.photographer"
                  multiple
                  placeholder="请选择摄影师"
                  :disabled="dialogType === 2 || dialogType === 3"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in photographers"
                    :key="item.photographerName"
                    :label="item.photographerName"
                    :value="item.photographerName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="毕业时间" prop="graduationYear">
                <el-date-picker
                  style="width: 280px"
                  v-model="taskForm.graduationYear"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择开始日期"
                  :disabled="dialogType === 2"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="dialogType === 2 || dialogType === 3">
              <!-- 编辑学历 -->
              <el-form-item label="学历" prop="educationType">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.educationType"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- 编辑学历 -->
            <el-col :span="12" v-if="dialogType === 1">
              <el-form-item label="学历层次" prop="educationType">
                <el-select
                  style="width: 280px"
                  v-model="taskForm.educationType"
                  multiple
                  placeholder="请选择学历"
                  :disabled="dialogType === 2 || dialogType === 3"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in educationTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="12">
              <el-form-item label="是否加急" prop="urgentType">
                <el-radio-group
                  v-model="taskForm.urgentType"
                  :disabled="dialogType === 2 || dialogType === 3"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row v-show="dialogType != 2">
              <el-col :span="12">
                <el-form-item label="任务时段">
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    @click="
                      handleTaskTime(
                        taskForm.id,
                        taskForm.beginDate,
                        taskForm.endDate
                      )
                    "
                    >任务时段管理
                  </el-button>
                </el-form-item>
              </el-col> -->
          <!-- <el-col :span="12"></el-col> -->
          <!-- </el-row> -->

          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="content">
                <el-input
                  style="width: 80%"
                  :disabled="dialogType === 2"
                  v-model="taskForm.content"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="dialogType !== 2"
          type="primary"
          @click="handleConfirm"
          :disabled="confirmLoading == true"
          :loading="confirmLoading"
          >保 存
        </el-button>
      </div>
    </el-dialog>

    <!--查看任务下的学生信息-->
    <el-dialog
      title="学生信息"
      :visible.sync="dlgViewStudentVisible"
      width="1500px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div style="display: flex; flex-wrap: wrap">
        <div class="condition-item">
          <label>订单号</label>
          <el-input
            clearable
            v-model="searchStu.searchStuOrderNo"
            placeholder="请输入订单号"
          ></el-input>
        </div>

        <div class="condition-item">
          <label>学号</label>
          <el-input
            clearable
            v-model="searchStu.searchStuCode"
            placeholder="请输入学号"
          ></el-input>
        </div>
        <div class="condition-item">
          <label>采集码</label>
          <el-input
            clearable
            v-model="searchStu.searchStudentCjm"
            placeholder="请输入采集码"
          ></el-input>
        </div>
        <div class="condition-item">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearchStudent"
            >查询
          </el-button>
          <el-button
            type="success"
            icon="el-icon-s-data"
            :loading="ExportNotPayXpLoading"
            @click="handleExportNotPayXp"
          >
            导出未采集学生
          </el-button>
        </div>
      </div>
      <div style="margin-bottom: 20px; flex: 1; overflow: hidden">
        <el-table
          :data="studentList"
          stripe
          border
          height="480px"
          style="width: 100%"
        >
          <template #empty>
            <p>{{ tableStudentLoading == true ? "数据加载中" : "暂无数据" }}</p>
          </template>

          <el-table-column
            prop="orderNo"
            label="订单号"
            align="center"
            width="240"
          >
          </el-table-column>
          <el-table-column
            prop="studentName"
            label="姓名"
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                execDecrypt(scope.row.studentName, "name")
              }}</span></template
            >
          </el-table-column>

          <el-table-column
            prop="studentCode"
            label="学号"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="educationType"
            label="学历层次"
            width="90"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="studentIdNumber"
            label="证件号码"
            width="260"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                execDecrypt(scope.row.studentIdNumber, "Idcard")
              }}</span></template
            >
          </el-table-column>

          <el-table-column
            prop="schoolCode"
            label="院校代码"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="院校名称"
            width="240"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="classes"
            label="班级"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="branchCollege"
            label="分院"
            width="200"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="cjm" label="采集码" width="180" align="center">
          </el-table-column>
          <el-table-column
            prop="collectedTime"
            label="采集时间"
            :formatter="columnDateFormat"
            width="160"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="graduationTime"
            label="毕业时间"
            :formatter="columnDateFormat"
            width="160"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pathCollectedPhoto"
            label="状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.pathCollectedPhoto == "" ? "未采集" : "已采集" }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="display: flex; justify-content: center">
        <el-pagination
          @size-change="handleStudentSizeChange"
          @current-change="handleStudentCurrentChange"
          :current-page="studentCurrentPage"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="studentPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="studentTotal"
        >
        </el-pagination>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button @click="dlgViewStudentVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 上传学生信息 -->
    <el-dialog
      title="上传学生信息"
      :visible.sync="stuUplodShow"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-upload
        class="upload-demo"
        ref="uploadImport"
        :multiple="false"
        action=""
        :limit="1"
        :on-change="handleUploadChange"
        :file-list="[]"
        accept=".rar,.zip"
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
          :loading="stuUpdateLoading"
          :disabled="stuUpdateLoading"
          >{{ stuUpdateLoading ? "学生信息上传中..." : "选取文件" }}
        </el-button>
        <div slot="tip" class="el-upload__tip">
          <a :href="'/temp/educationTemp_XP.zip?ver=' + times">下载校拍模板</a>
          <a
            :href="'/temp/educationTemp_SP.zip?ver=' + times"
            style="margin-left: 10px"
            >下载散拍模板</a
          >
          <a
            :href="'/temp/onlinecollection.zip?ver=' + times"
            style="margin-left: 10px"
            >下载线上采集模板</a
          >
        </div>
      </el-upload>
    </el-dialog>
    <!-- 导入进度 -->
    <el-dialog
      title="导入进度"
      :visible.sync="ViewrecordVisible"
      width="1100px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table
        :data="ViewrecordList"
        v-loading="ViewrecordLoading"
        stripe
        border
        height="450"
        style="width: 100%"
      >
        <template #empty>
          <p>{{ ViewrecordLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="fileRealName" label="文件名称" width="360">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="导入时间"
          :formatter="columnDateFormat"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="导入状态"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status === 0"
              type="primary"
              size="small"
              effect="plain"
              >新建</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 1"
              type="success"
              size="small"
              effect="plain"
              >导入中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 2"
              type="success"
              size="small"
              effect="plain"
              >导入成功</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 3"
              type="danger"
              size="small"
              effect="plain"
              >导入失败</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="resultMsg" label="导入结果" width="270">
          <template slot-scope="scope">
            <span v-html="scope.row.resultMsg"></span>
          </template>
        </el-table-column>
        <el-table-column prop="createBy" label="操作人"> </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button @click="ViewrecordVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryTasks,
  addTasks,
  collectTask,
  collectUploadList,
  collectTaskInfo,
  queryStudents,
} from "@/api/encryption";
import { minioUpload, merge } from "@/api/order";
import { exportprocess } from "@/api/student";
import { dateFormat } from "@/utils/date";
import { getSchoolFiller, getSchool } from "@/api/school";
import { queryParam } from "../../../api/param.js";
import { queryPageButton } from "@/api/permission";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";

import SparkMD5 from "spark-md5";
const chunkSize = 5 * 1024 * 1024;

export default {
  name: "JMTaskMG",
  data() {
    var res = /^\d+$|^\d+[.]?\d+$/;
    var tells = /^[1]([3-9])[0-9]{9}$/;
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("任务编码不能为空"));
      } else {
        callback();
      }
    };
    const validateTitle = (rule, value, callback) => {
      if (!value) {
        callback(new Error("计划标题不能为空"));
      } else {
        callback();
      }
    };
    const validateSchoolId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学校信息不能为空"));
      } else {
        callback();
      }
    };

    const validateCollectType = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择采集类型"));
      } else {
        callback();
      }
    };
    const validateuUrgentType = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否加急"));
      } else {
        callback();
      }
    };

    const validateBeginDate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("采集开始日期不能为空"));
      } else {
        callback();
      }
    };
    const validateEndDate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("采集结束日期不能为空"));
      } else {
        callback();
      }
    };
    const validateShotPrice = (rule, value, callback) => {
      if (value != "0" && !value) {
        callback(new Error("收费标准不能为空"));
      } else {
        if (res.test(value) == false) {
          callback(new Error("格式不正确，只能输入数字"));
        } else {
          callback();
        }
      }
    };
    const validateStatus = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("状态不能为空"));
      } else {
        callback();
      }
    };
    const validateContactTell = (rule, value, callback) => {
      if (!value) {
        callback(new Error("电话不能为空"));
      } else {
        if (tells.test(value) == false) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };

    const validateContactPerson = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人"));
      } else {
        callback();
      }
    };

    const validatePhotographer = (rule, value, callback) => {
      if (!value) {
        callback(new Error("摄影师不能为空"));
      } else {
        callback();
      }
    };

    return {
      times: Date.now() * 1,
      // 列表搜索
      listSearch: {
        title: "",
        schoolId: null,
        stime: null,
        etime: null,
        schoolName: "",
        shotSubject: "",
        isBatch: "",
      },
      // 列表分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      taskId: 0, //任务id
      dialogType: 1, //新增or修改参数
      //学校
      schools: [],
      value: [],
      allSchools: [],
      loading: false,

      // 新增or修改页面参数
      beginDate: null,
      endDate: null,
      tableLoading: false, //页面加载
      dialogVisible: false,
      taskList: [],
      //学历
      educationTypeOptions: [
        {
          value: "成教",
          label: "成教",
        },
        {
          value: "全日制本专科",
          label: "全日制本专科",
        },
        {
          value: "全日制研究生",
          label: "全日制研究生",
        },
      ],
      taskForm: {
        schoolId: null,
      },
      taskDetailForm: {},
      photographers: [],
      taskRules: {
        code: [
          {
            required: true,
            trigger: "blur",
            validator: validateCode,
          },
        ],
        title: [
          {
            required: true,
            trigger: "blur",
            validator: validateTitle,
          },
        ],
        schoolId: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchoolId,
          },
        ],

        collectType: [
          {
            required: true,
            trigger: "blur",
            validator: validateCollectType,
          },
        ],
        urgentType: [
          {
            required: true,
            trigger: "blur",
            validator: validateuUrgentType,
          },
        ],

        beginDate: [
          {
            required: true,
            trigger: "blur",
            validator: validateBeginDate,
          },
        ],
        endDate: [
          {
            required: true,
            trigger: "blur",
            validator: validateEndDate,
          },
        ],
        shotPrice: [
          {
            required: true,
            trigger: "blur",
            validator: validateShotPrice,
          },
        ],
        status: [
          {
            required: true,
            trigger: "blur",
            validator: validateStatus,
          },
        ],
        contactTell: [
          {
            required: true,
            trigger: "blur",
            validator: validateContactTell,
          },
        ],
        contactPerson: [
          {
            required: true,
            trigger: "blur",
            validator: validateContactPerson,
          },
        ],
        photographer: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhotographer,
          },
        ],
      },

      confirmLoading: false, //新增保存
      ExportNotPayXpLoading: false, //下载加载
      packageLoading: false,
      finishedLoading: false, //结束加载

      //拍摄日期范围
      pickerOptions: {
        disabledDate: this.setShotDateRange,
      },
      //日历
      selectDate: new Date(),
      Calendar: [],
      form: {
        id: null,
        EventTitle: null,
        EventTime: null,
      },
      calendarEvents: [],
      dialogFormVisible: false,
      optTitle: "添加事件",
      newDate: new Date(),

      //学生情况
      studentList: [],
      dlgViewStudentVisible: false,
      studentCurrentPage: 1,
      studentPageSize: 20,
      studentTotal: 0,
      tableStudentLoading: false,
      searchStu: {
        searchStuOrderNo: "",
        searchStuCode: "",
        searchStudentCjm: "",
      },

      TexTloading: null, // 打包下载

      //   查看导入记录
      ViewrecordVisible: false,
      ViewrecordLoading: false,
      ViewrecordList: [],

      //按钮权限
      searchButton: false,
      addButton: false,
      updateButton: false,
      deleteButton: false,
      viewButton: false,
      taskTimeButton: false,
      photographerButton: false,
      downloadXpButton: false,
      downloadXpFailButton: false,
      enableButton: false,
      finishedButton: false,
      cencelButton: false,
      viewStudentButton: false,
      taskId: null,
      stuUplodShow: false,
      stuUpdateLoading: false,
    };
  },
  created() {
    this.querySchools();
    this.queryButton();
    this.query();
  },
  methods: {
    queryButton() {
      const data = 3;
      queryPageButton(data).then((resp) => {
        // console.log(resp);
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "add") {
            this.addButton = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "update") {
            this.updateButton = true;
          }
          if (button.code === "task_time") {
            this.taskTimeButton = true;
          }
          if (button.code === "photographer") {
            this.photographerButton = true;
          }
          if (button.code === "downloadXp") {
            this.downloadXpButton = true;
          }
          if (button.code === "downloadXpFail") {
            this.downloadXpFailButton = true;
          }
          if (button.code === "enable") {
            this.enableButton = true;
          }
          if (button.code === "finished") {
            this.finishedButton = true;
          }
          if (button.code === "cencel") {
            this.cencelButton = true;
          }
          if (button.code === "viewStudent") {
            this.viewStudentButton = true;
          }
          // if (button.code === "uploadStuInfo") {
          //   this.isUploadInfo = true;
          // }
        }
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    // 读取配置摄影师
    queryParam() {
      queryParam().then((resp) => {
        let arrList = [];
        arrList = resp.data.photographer;
        let arr = arrList.split(",");
        let res = [];
        arr.forEach((item) => {
          res.push({ photographerName: item });
        });
        this.photographers = res;
        // console.log(this.photographers);
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    query() {
      this.tableLoading = true;
      const data = this.listSearch;
      queryTasks(this.currentPage, this.pageSize, data).then((resp) => {
        if (resp.code == 0) {
          this.taskList = resp.data.content;
          this.total = resp.data.totalElements;
        }
        this.tableLoading = false;
      });
    },

    handleSearch() {
      this.currentPage = 1;
      this.query();
    },

    setShotDateRange(time) {
      return (
        time.getTime() < new Date(this.beginDate).getTime() ||
        time.getTime() > new Date(this.endDate).getTime()
      );
    },

    // 查看记录
    handleViewrecord(taskId) {
      this.ViewrecordList = [];
      this.ViewrecordVisible = true;
      this.ViewrecordLoading = true;
      collectUploadList(taskId).then((res) => {
        this.ViewrecordLoading = false;
        if (res.code == 0) {
          this.ViewrecordList = res.data;
        }
      });
    },
    //新建采集
    handleAdd() {
      this.querySchools();
      this.queryParam();
      this.taskForm = {
        id: null,
        collectType: 0,
        urgentType: 1,
        contactPerson: "",
        contactTell: "",
      };
      if (this.$refs.taskForm !== undefined) {
        this.$refs.taskForm.resetFields();
      }
      this.dialogType = 1;
      this.dialogVisible = true;
      // this.getCode();
    },
    // 新增方法
    add() {
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.taskForm;
          this.taskForm.photographer = this.taskForm.photographer.join(",");
          this.taskForm.educationType = this.taskForm.educationType.join(",");
          addTasks(data)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify({
                  title: "新增任务成功",
                  message: resp.message,
                  type: "success",
                });
              }
              this.confirmLoading = false;
              this.dialogVisible = false;
              this.dialogType = 3;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
              this.dialogVisible = false;
            });
        } else {
          return false;
        }
      });
    },
    // 修改方法
    update() {
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.taskForm;
          addTasks(data)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify({
                  title: "修改任务成功",
                  message: "修改任务成功",
                  type: "success",
                });
              }

              this.confirmLoading = false;
              this.dialogVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.confirmLoading = false;
              this.dialogVisible = false;
            });
        } else {
          return false;
        }
      });
    },
    handleView(id) {
      if (this.$refs.taskForm !== undefined) {
        //重置表单信息
        this.$refs.taskForm.resetFields();
      }
      collectTaskInfo(id)
        .then((resp) => {
          this.taskForm = resp.data;
          this.dialogType = 2;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    // 修改
    handleUpdate(id) {
      this.confirmLoading = false;
      if (this.$refs.taskForm !== undefined) {
        //重置表单信息
        this.$refs.taskForm.resetFields();
      }
      collectTaskInfo(id)
        .then((resp) => {
          if (resp.code == 0) {
            this.taskForm = resp.data;
            this.dialogType = 3;
            this.dialogVisible = true;
          }
        })
        .catch((error) => {});
    },
    handleDownloadXp(id) {
      let postData = {
        taskId: id,
      };
      this.TexTloading = this.$loading({
        lock: true,
        text: "文件打包中....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axios({
        method: "POST",
        dataType: "json",
        url: "api/csias/sys/collectTask/packages",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        timeout: 6000000,
        data: postData,
      }).then(
        (response) => {
          if (response.status === 200) {
            this.taskExportprocess(response.data.taskNo);
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到照片信息",
            });
            this.TexTloading.close();
          }
        },
        (err) => {
          this.TexTloading.close();
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到照片信息",
          });
        }
      );
    },
    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo = "") {
      let that = this;
      exportprocess(taskNo).then((res) => {
        if (res.data.state == 0) {
          setTimeout(() => {
            that.taskExportprocess(taskNo);
          }, 500);
        } else if (res.data.state == 1) {
          that.$notify({
            title: "成功",
            message: "打包下载成功",
            type: "success",
          });
          if (res.data.msg == "打包成功") {
            this.TexTloading.close();
          }
          setTimeout(() => {
            let urlFile = res.data.url;
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = urlFile;
            let fileName = Date.parse(new Date()) + ".rar";
            link.download = fileName;
            // console.log(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            this.queryLIst();
          }, 500);
        } else {
          this.$notify({
            title: "警告",
            message: res.data.msg[0],
            type: "warning",
          });
          this.TexTloading.close();
          this.queryLIst();
        }
      });
    },
    // 查看学生信息
    handleViewStudent(id) {
      this.searchStu = {};
      this.studentList = [];
      this.dlgViewStudentVisible = true;
      if (this.$refs.dlgViewStudentVisible !== undefined) {
        this.$refs.dlgViewStudentVisible.resetFields();
      }
      this.studentCurrentPage = 1;
      this.queryStudent(id);
    },
    /**
     * 弹出上传学生
     */
    handleViewStuUpload(taskId) {
      this.taskId = taskId;
      this.stuUplodShow = true;
    },
    handleSearchStudent() {
      this.studentCurrentPage = 1;
      this.queryStudent();
    },
    queryStudent(id) {
      this.tableStudentLoading = true;
      const data = {
        taskId: id,
        orderNo: this.searchStu.searchStuOrderNo,
        studentCode: this.searchStu.searchStuCode,
        cjm: this.searchStu.searchStudentCjm,
      };
      queryStudents(this.studentCurrentPage, this.studentPageSize, data).then(
        (resp) => {
          this.studentList = resp.data.content;
          this.studentTotal = resp.data.totalElements;
          this.tableStudentLoading = false;
        }
      );
    },
    handleExportNotPayXp() {
      //window._config["baseUrl"] +
      const url = "api/csias/sys/collectOrder/exportCjmNotPayXp/" + this.taskId;
      this.exportExcel(url);
    },
    exportExcel(url) {
      this.ExportNotPayXpLoading = true;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            // console.log(response.data);
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });

            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.ExportNotPayXpLoading = false;
        },
        (err) => {
          this.ExportNotPayXpLoading = false;
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },
    handleFinished(id) {
      this.$confirm("是否确认结束该任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.finishedLoading = true;
          collectTask(id, 1)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify({
                  title: "结束任务成功",
                  message: resp.message,
                  type: "success",
                });
              }
              this.finishedLoading = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleCencel(id) {
      this.$confirm("是否确认取消该任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.finishedLoading = true;
          collectTask(id, 2)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify({
                  title: "取消任务成功",
                  message: resp.message,
                  type: "success",
                });
              }

              this.finishedLoading = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleConfirm() {
      if (this.dialogType === 1) {
        this.add();
      } else if (this.dialogType === 3) {
        this.update();
      }
    },
    //选择学校的时候带出学校联系人和联系电话
    selectSchool(schoolId) {
      getSchool(schoolId).then((resp) => {
        this.taskForm.contactPerson = resp.data.contactPerson;
        this.taskForm.contactTell = resp.data.contactTell;
      });
    },
    getCode() {
      getTaskCode().then((resp) => {
        if (resp.code == 0) {
          this.taskForm.code = resp.data;
        }
        // console.log(this.taskForm.code);
      });
    },
    /**
     * 上传学生信息
     */
    async handleUploadChange(file, fileList) {
      this.stuUpdateLoading = true;
      if (file.raw) {
        let fileData = file.raw;
        const fileMd5 = await this.getFileMd5(fileData);
        let i = 0;
        this.PostFile(fileData, i, fileMd5);
      }
    },
    // 分片上传文件
    PostFile(file, i, fileMd5) {
      let fileName = file.name,
        fileType = file.type,
        fileSize = file.size,
        chunkCount = Math.ceil(fileSize / chunkSize); //总片数
      if (i >= chunkCount) {
        return;
      }
      let start = i * chunkSize;
      let end = start + chunkSize;
      let packet = file.slice(start, end); //将文件进行切片
      // console.log(packet);
      let form = new FormData();
      form.append("md5", fileMd5);
      form.append("file", packet); //slice方法用于切出文件的一部分
      form.append("name", fileName);
      form.append("totalSize", fileSize);
      form.append("total", chunkCount); //总片数
      form.append("index", i + 1); //当前是第几片
      minioUpload(form).then((resp) => {
        if (resp.data.status === 20001) {
          /*  表示上一块文件上传成功，继续下一次  */
          form = "";
          i++;
          this.PostFile(file, i, fileMd5);
        } else if (resp.data.status === 50000) {
          form = "";
          /*  失败后，每2秒继续传一次分片文件  */
          setInterval(function () {
            this.PostFile(file, i, fileMd5);
          }, 2000);
        } else if (resp.data.status === 20002) {
          this.merge(chunkCount, fileName, fileMd5, fileSize, fileType);
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        } else {
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        }
      });
    },
    // 合并分片文件
    merge(chunkCount, fileName, fileMd5, fileSize, fileType) {
      let postData = {
        shardCount: chunkCount,
        md5: fileMd5,
        fileName: fileName,
        fileType: fileType,
        fileSize: fileSize,
      };
      merge(postData).then((resp) => {
        if (resp.code == 0) {
          let uploadData = {
            fileName: resp.data.fileName,
            taskId: this.taskId,
            fileRealName: fileName,
          };
          // console.log(uploadData);
          axios({
            url: "/api/csias/sys/collectUpload/uploadFile",
            method: "POST",
            dataType: "json",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getToken(),
            },
            data: uploadData,
            timeout: 180000,
          }).then((response) => {
            if (response.data.code == 0) {
              this.stuUplodShow = false;
              this.stuUpdateLoading = false;
              this.$notify({
                title: "温馨提示",
                message: response.data.message,
                type: "success",
              });
            } else {
              this.stuUplodShow = false;
              this.stuUpdateLoading = false;
              this.$notify({
                title: "警告",
                message: response.data.message,
                type: "warning",
              });
            }
          }),
            (err) => {
              this.stuUplodShow = false;
              this.stuUpdateLoading = false;
              this.$notify.error({
                title: "错误",
                message: response.data.message,
              });
            };
        } else {
          this.stuUplodShow = false;
          this.stuUpdateLoading = false;

          this.$notify({
            title: "警告",
            message: "未知错误",
            type: "warning",
          });
        }
      });
    },
    // md5文件转化
    getFileMd5(file, chunkCount) {
      return new Promise((resolve, reject) => {
        let blobSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
        let chunks = chunkCount;
        let currentChunk = 0;
        let spark = new SparkMD5.ArrayBuffer();
        let fileReader = new FileReader();

        fileReader.onload = function (e) {
          spark.append(e.target.result);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
          } else {
            let md5 = spark.end();
            resolve(md5);
          }
        };
        fileReader.onerror = function (e) {
          reject(e);
        };
        function loadNext() {
          let start = currentChunk * chunkSize;
          let end = start + chunkSize;
          if (end > file.size) {
            end = file.size;
          }
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        loadNext();
      });
    },
    //列表分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    // 学生信息分页
    handleStudentSizeChange(val) {
      this.studentPageSize = val;
      this.queryStudent();
    },
    handleStudentCurrentChange(val) {
      this.studentCurrentPage = val;
      this.queryStudent();
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd ", new Date(dateValue));
      }
    },
    columnTimeFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("HH时MM分", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>
<style>
.tip {
  background-color: #f9f9f9;
  border-radius: 4px;
  margin: 5px 0;
  line-height: 50px;
  font-weight: bolder;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 50px;
  line-height: 50px;
  vertical-align: bottom;
}

.gray {
  background-color: #f2f2f2;
}

.diviceRow {
  width: 1200px;
}

.diviceRow .col-1eft {
  float: left;
  width: 600px;
  height: 260px;
}

.diviceRow .col-right {
  float: left;
  display: flex;
  width: 555px;
  height: 250px;
}

.calendar-day {
  text-align: center;
  color: #202535;
  line-height: 30px;
  font-size: 12px;
}

.el-calendar-table .el-calendar-day {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px;
  height: 150px;
}

.el-calendar-table .el-calendar-click {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px;
  height: 100px;
}

.el-calendar-table thead th {
  padding: 6px 0;
  color: #606266;
  font-weight: normal;
  text-align: center;
}

.el-calendar-table:not(.is-range) td.next,
.el-calendar-table:not(.is-range) td.prev {
  opacity: 0.7;
}

.fc-day-grid-event {
  margin: 0;
  padding: 0;
  /*border: solid 1px blue;*/
}

.day-value {
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 12px;
}

#full_subscribe .spanValue {
  /*border: 1px solid #F5DEB3;*/
  /*background-color: #E6A23C;*/
  font-weight: bolder;
  color: #f56c6c;
}

#surplus_subcribe .spanValue {
  /*border: 1px solid #C1FFC1;*/
  /*background-color: #67C23A;*/
  font-weight: bolder;
  color: #67c23a;
}

.fc-day-grid-event:hover .day-button {
  visibility: initial;
}

.dayTitle {
  visibility: hidden;
}

.el-calendar-day:hover .dayTitle {
  visibility: initial;
}

.el-calendar-table .el-calendar-day {
  height: 70px;
}

.is-selected {
  color: #1989fa;
}
</style>
