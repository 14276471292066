import request from "@/utils/request";

//c采集列表
export function queryTasks(currentPage, pageSize, data = {}) {
  return request({
    url:
      "/sys/collectTask/queryTasks?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

//新增采集
export function addTasks(data) {
  return request({
    url: "/sys/collectTask/addTask",
    headers: {
      "Content-Type": "application/json",
    },
    processData: false,
    contentType: false,
    method: "post",
    data,
  });
}

// 任务状态
export function collectTask(taskId, status) {
  return request({
    url:
      "/sys/collectTask/setTaskStatus?taskId=" + taskId + "&status=" + status,
    method: "post",
  });
}

//查看导入进度
export function collectUploadList(taskId) {
  return request({
    url: "/sys/collectUpload/list?taskId=" + taskId,
    method: "post",
  });
}

//查看详情
export function collectTaskInfo(taskId) {
  return request({
    url: "/sys/collectTask/index/" + taskId,
    method: "get",
  });
}

//学生信息
export function queryStudents(currentPage, pageSize, data = {}) {
  return request({
    url:
      "/sys/collectOrder/queryStudents?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

//订单采集列表
export function queryOrders(currentPage, pageSize, data = {}) {
  return request({
    url:
      "/sys/collectOrder/queryOrders?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}


//订单日志
export function getLogs(taskId) {
  return request({
    url: "/sys/collectOrder/getLogs/" + taskId,
    method: "get",
  });
}

//订单详情
export function collectOrderInfo(orderId) {
  return request({
    url: "/sys/collectOrder/index/" + orderId,
    method: "get",
  });
}

//上传照片
export function uploadPhoto(data) {
  return request({
    url: "/sys/collectOrder/uploadPhoto",
    method: "post",
    data,
  });
}


//重传照片
export function reUploadPhoto(data) {
  return request({
    url: "/sys/collectOrder/reUploadPhoto",
    method: "post",
    data,
  });
}

// 修改邮寄地址
export function updateMailinfo(data) {
  return request({
    url: "/sys/collectOrder/updateMailinfo",
    method: "post",
    data,
  });
}